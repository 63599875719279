import React from 'react';
import Section from '../../../components/section';
import { PageHero } from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import '../styles.scss';

const UseCasesItem = () => {
  return (
    <Layout>
      <PageHero
        title="Receive alerts when products become available or go out of stock"
        subtitle=""
      />
      <Section>
        <div className="use-cases-item">
          <div className="go-back">
            <a className="learn_btn_two" href="/use-cases">
              <svg width="40px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="long-arrow-alt-left" className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                </path>
              </svg>Back to use cases</a>
          </div>

          <div className="content-block">
            <p>Stay in the loop when it comes to the stock levels and product availability for your business and personal shopping.</p>
            <h4>Why should you monitor product pages on e-commerce sites?</h4>
            <p>Most businesses rely on 3rd party products or services to provide their own products or services, 
              for example, if you are an e-commerce business, you will likely make use of product suppliers, 
              packaging supplies, or use shipping services to deliver your products. Therefore It is important for a 
              business or a person to know if certain products or services become newly available, falls below a defined 
              threshold, and/or becomes unavailable.</p>
            <p>Let’s see how Hexowatch can help you monitor this information.</p>

            <h5>Learn when online inventories reach certain levels</h5>
            <p>Knowing when to order or when to manufacture new stock is a timeless problem, one which can cost a business a lot of money.
            Timing is key because pricing and availability can be volatile, and it can take a few weeks for new stock to arrive, and a
                 business needs to ensure that they have it before they stock out in their shops. </p>

            <h4>Be the first to know when shipping disruptions occur</h4>
            <p>Shipping services can be affected by external events and if you’re an e-commerce business this can
            impact your ability to ship and deliver products in a timely fashion. By monitoring the updates pages of your
            key shipping providers you can get advance warnings when things change so you can adjust your shipping methods
                or update your customers before it impacts them.</p>

            <h4>Get notifications when products are back in stock</h4>
            <p>High in demand products typically go out of stock, so keeping an eye on your suppliers can help you order before items go out of stock, or re-order as soon as new stock is available.</p>
            <p>If you entertain clients, event tickets or popular gift items can sell out in a flash,
            and they can often reappear online when they are being sold for a tidy profit on secondary markets.
            It is good for your pocket to know when or if a popular product becomes available again, especially
                if they are being sold for a lower price.</p>

            <h4>How to monitor product pages for availability</h4>
            <p>Manual monitoring is time-consuming and it can lead to lost sales, and this is true for individuals who want to make a profit out of selling tickets or sought-after products on the secondary
                  market or retailers who need to know when to place their next inventory order.</p>
            <p>Here is how you can streamline the process with Hexowatch</p>

            <p>Step 1: Sign up for Hexowatch account.</p>
            <p>Step 2: Create a monitor for each product page or service provider, choosing your alert criteria</p>
            <p>Step 3: Define how frequently you want the check to happen</p>
            <p>Step 4: Go back to work safe in the knowledge that Hexowatch will alert you as soon as changes occur.</p>

            <h4>Go beyond product & service availability monitoring</h4>
            <p>Hexowatch is your AI sidekick to monitor any website for visual, content, source code, technology, availability or price changes.</p>
            <p>Hexowatch works 24/7 to help spot trends, spy on your competitors, visually check your website, keep an archive of every change and turn
              any website into your own private data source accessing changes as a downloadable CSV file, google sheets or via Zapier.</p>
          </div>

          <a href="/pricing">
            Click here to monitor any website and get started in minutes - no software, proxies, or programming required
          </a>
        </div>
      </Section>
    </Layout>
  );
};

export default UseCasesItem;
